

























































































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IClient } from "@/core/_models/client.model";
// Constants
import { allDaysOfWeek, DayOfWeek } from "@/core/_constants/daysOfWeek.constant";
// Services
import AdminService from "./Admin.service";
// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// Components
import DaysSelector from "@/core/components/content/DaysSelector.vue";

export default Vue.extend({
  name: "Settings",

  components: { DaysSelector },

  data() {
    return {
      isLoading: false,
      isConfirmed: false,

      name: "",
      companyLogo: "",
      mainThemeColor: "",
      myFile: null,
      workingDays: [] as DayOfWeek[]
    };
  },

  mixins: [validationMixin],

  validations: {
    mainThemeColor: { required },
    workingDays: { required }
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    initialize(): void {
      this.name = this.currentUser.client.Name;
      this.companyLogo = this.currentUser.client.CompanyLogo;
      this.mainThemeColor = this.currentUser.client.MainThemeColor;
      this.workingDays = this.currentUser.client.WorkingDays ?? allDaysOfWeek;
    },

    submit(): void {
      this.isLoading = true;

      // Build payload
      const payload: IClient = {
        DocumentName: this.currentUser.client.DocumentName,
        Name: this.name,
        CompanyLogo: this.companyLogo,
        MainThemeColor: this.mainThemeColor,
        MainThemeTextColor: "#FFFFFF",
        JobSchedulerTimeIntervals: this.currentUser.client.JobSchedulerTimeIntervals,
        WorkingDays: this.workingDays
      };

      // Handles submission if a user uploads a new Company Logo
      if (this.myFile) {
        const uploadFile = this.myFile as unknown as File;
        const filePath = `Clients/${this.currentUser.client.DocumentName}/CompanyLogo.${uploadFile.name
          .split(".")
          .pop()}`;

        const storageRef = firebase.default.storage().ref(filePath).put(uploadFile);

        storageRef.snapshot.ref.getDownloadURL().then((url) => {
          payload.CompanyLogo = url;

          AdminService.updateClient(this.currentUser.clientId, payload).then();
        });

        storageRef.on(
          "state_changed",
          (snaphot) => {
            // console.log((snaphot.bytesTransferred / snaphot.totalBytes) * 100);
          },
          (error) => {
            // console.log(error.message);
          },
          () => {
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              payload.CompanyLogo = url;

              AdminService.updateClient(this.currentUser.clientId, payload).then((response: any) => {
                if (!response) return;

                handleSubmitSuccess();
              });
            });
          }
        );
      }

      // Handles submission if the user didn't upload a Company Logo
      else {
        AdminService.updateClient(this.currentUser.clientId, payload).then((response: any) => {
          if (!response) return;

          handleSubmitSuccess();
        });
      }

      const handleSubmitSuccess = () => {
        this.$vuetify.theme.themes.light.primary = this.mainThemeColor;
        this.currentUser.client.MainThemeColor = this.mainThemeColor;

        this.currentUser.client.WorkingDays = this.workingDays;

        if (payload.CompanyLogo) {
          this.currentUser.client.CompanyLogo = payload.CompanyLogo;
          this.companyLogo = payload.CompanyLogo;
        }

        this.isConfirmed = false;
        this.isLoading = false;
        this.ADD_SUCCESS_MESSAGE(`Settings successfully updated.`);
      };
    },

    close(): void {
      this.name = "";
      this.companyLogo = "";
      this.mainThemeColor = "";
      this.myFile = null;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  }
});
